






































































import './scss/PopupHelp.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { IProject, IMarkKit } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { convertFileToBase64 } from '@utility/fileConverter.js';

const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');

import { ProjectActions } from '@store/modules/project/Types';
import { isEmail, isPhone } from '@utility/rules.js';
import { IUserState } from "@store/modules/user/Interfaces";

@Component({
    name: 'PopupHelp',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    }
})

export default class PopupHelp extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        form: HTMLFormElement,
        fileInput: HTMLFormElement,
        fieldName: HTMLFormElement,
        fieldPhone: HTMLFormElement,
        fieldEmail: HTMLFormElement,
        fieldMessage: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Getter('projectMarkKits') projectMarkKits!: IMarkKit[];
    @NSProject.Action(ProjectActions.A_SEND_FEEDBACK) sendFeedback!: (data) => Promise<void>
    @NSUser.Getter('userData') userData!: IUserState;

    fields: any = {
        name: '',
        phone: '',
        email: '',
        text: '',
        files: [],
    };

    errors: any = {
        name: '',
        phone: '',
        email: '',
        text: '',
    };

    packPDId:string = '';
    realtimeValidation = false;
    btnDisabled = false;

    @Watch('fields', { deep: true })
    onChangeFields() {
        this.checkValidation();
    }

    @Watch('userData', { deep: true })
    onChangeUserData() {
        this.setBaseFieldsValue();
    }

    mounted() {
        this.setBaseFieldsValue();
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get errorPhoneFormat() {
        return this.errors.phone && this.errors.phone === 'format';
    }

    get errorEmailFormat() {
        return this.errors.email && this.errors.email === 'format';
    }

    get errorText() {
        if (this.errorExists) {
            return this.errorPhoneFormat ? 'Недопустимый формат номера телефона'
                : this.errorEmailFormat ? 'Недопустимый формат E-mail'
                    : 'Вы не заполнили одно или несколько обязательных полей';
        }
        return '';
    }

    setBaseFieldsValue() {
        if (this.userData && this.userData.userInfo) {
            this.fields.name = this.userData.userInfo.fullName;
            this.fields.phone = this.userData.userInfo.phone;
            this.fields.email = this.userData.userInfo.email;
        }
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
        this.setFocusOnOpen();
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    sendForm() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            const files: any[] = [];
            let convertedFilesPromise: Promise<any>[] = [];
            let data = {
                name: this.fields.name,
                email: this.fields.email,
                text: this.fields.text,
                files: files,
            };

            if (this.fields.phone) {
                data['phone'] = this.fields.phone;
            }

            if (this.fields && this.fields.files && this.fields!.files!.length) {
                convertedFilesPromise = this.fields.files.map((fileObj, fileIndex) => {
                    const splitTitle = fileObj.name.split('.');

                    return convertFileToBase64(fileObj).then(file => {
                        files.push({
                            name: fileObj.name.replace(`.${splitTitle[splitTitle.length - 1]}`, ''),
                            format: splitTitle[splitTitle.length - 1],
                            data: file.replace(`data:${fileObj.type};base64,`, ''),
                        });

                        if (fileIndex === this.fields.files.length - 1) {
                            data.files = files;
                        }
                    });
                })
            }

            Promise.all(convertedFilesPromise)
                .finally(() => {
                    this.sendFeedback(data).then(() => {
                        this.closePopup();
                        this.resetFields();
                        this.setBaseFieldsValue();
                        this.$emit('feedback-send-success');
                    }).finally(() => {
                        this.btnDisabled = false;
                    });
                });
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    resetFields() {
        this.fields.name = '';
        this.fields.phone = '';
        this.fields.email = '';
        this.fields.text = '';
        this.fields.files = [];

        this.errors.name = '';
        this.errors.phone = '';
        this.errors.email = '';
        this.errors.text = '';

        this.realtimeValidation = false;
    }

    validateFields() {
        this.errors.name = !this.fields.name ? 'required' : '';
        this.errors.phone = this.fields.phone ? !isPhone(this.fields.phone) ? 'format' : '' : '';
        this.errors.email = !this.fields.email ? 'required' : !isEmail(this.fields.email) ? 'format' : '';
        this.errors.text = !this.fields.text ? 'required' : '';

        this.realtimeValidation = true;
    }

    childrenIsFolder(folder) {
        return folder.children.filter(item => item.isFolder);
    }

    resetParent() {
        this.fields.parent = null;
    }

    setSelectedParent(e) {
        this.fields.parent = e;
    }

    onFileChange(e) {
        this.fields.files = e;
    }

    setFocusOnOpen() {
        if (!this.fields.name) {
            this.$refs.fieldName.focusInput();
        } else if (!this.fields.phone) {
            this.$refs.fieldPhone.focusInput();
        } else if (!this.fields.email) {
            this.$refs.fieldEmail.focusInput();
        } else if (!this.fields.text) {
            this.$refs.fieldMessage.focusInput();
        }
    }
}
