const isEmail = (value) => {
    // eslint-disable-next-line max-len
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-ZА-Яа-яЁёa-zA-Z\-0-9]+\.)+[a-zA-ZА-Яа-яЁёa-zA-Z]{2,}))$/;
    return pattern.test(value);
};

const isPhone = (value) => {
    const pattern = /^[+]?[0-9][\s]?[-(]?[0-9]{3}[-)]?[\s]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
    return pattern.test(value);
};

const isPassword = (value) => value.length >= 4;

const isImage = (value) => {
    const pattern = /[\s\S]{1,}\.jpg|[\s\S]{1,}\.JPG|[\s\S]{1,}\.jpeg|[\s\S]{1,}\.JPEG|[\s\S]{1,}\.png|[\s\S]{1,}\.PNG/;
    return pattern.test(value);
};

export {
    isEmail,
    isPhone,
    isImage,
    isPassword,
};
